import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import Slider from "react-slick";
import "./SimilarProperties.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import PriceComp from "@components/Property/Price"

// Header component

const Properties = (props) => {
    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: { 
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: { 
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: { 
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >

                <section className="similar-properties">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <h2><i>More</i> properties.</h2>
                                <div className="slick-wrap">
                                <Slider {...settings}>
                                {props.data.map((item, index) => {
                                    let uriStr = "";

                                    if (item.search_type === "sales") {
                                        uriStr = `property-for-sale-details/`
                                    } else if (item.search_type === "lettings") {
                                        uriStr = `property-to-rent-details/`
                                    }

                                    let processedImages = JSON.stringify({});
                                    if (item?.imagetransforms?.images_Transforms) {
                                        processedImages = item.imagetransforms.images_Transforms;
                                    }

                                    return (
                                    <div className="properties img-zoom">
                                        <Link to={'/'+uriStr+item.slug+'-'+item.id}>
                                        <ImageTransform imagesources={item.images[0].url} renderer="srcSet" imagename="property.images.similar_properties" attr={{ alt: item.display_address+ ' - Redbrik', class:'' }} imagetransformresult={processedImages} id={item._id}/>
                                        </Link>

                                        <div className="card-details">
                                            <h3>
                                                <Link to={'/'+uriStr+item.slug+'-'+item.id}>
                                                {item.display_address}
                                                </Link>

                                            </h3>
                                            <h4>
                                                <PriceComp {...item} />
                                            </h4>
                                            <div className="card-info">
                                                {item.building &&
                                                    <span className="details building"> 
                                                        {item.building.replace(/-/g, " ")}
                                                    </span>
                                                }
                                                {item.bedroom &&
                                                <span className="details">
                                                    <Link href="javascript:void(0)">
                                                        <i className="icon-bedroom"></i>
                                                    </Link>
                                                    {item.bedroom}
                                                </span>
                                                }
                                                {item.bathroom &&
                                                <span className="details">
                                                    <Link href="javascript:void(0)">
                                                        <i className="icon-bathroom"></i>
                                                    </Link>
                                                    {item.bathroom}
                                                </span>
                                                }
                                                {item.reception &&
                                                <span className="details">
                                                    <Link href="javascript:void(0)">
                                                        <i className="icon-chair"></i>
                                                    </Link>
                                                    {item.reception}
                                                </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}
                                </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
        </React.Fragment>
    );
};

export default Properties;
